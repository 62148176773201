import React, { useEffect, useMemo, useRef, useState } from "react";
import "./IncomeScreen.css";
import { ImagePath } from "../helper/ImagePath";
import ToggleButton from "../components/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import IncomePieChart from "../components/IncomePieChart";
import Pagintion from "../common/Pagination";
import { emptyTotalEarning, getAllEarning, getClientWiseIncomeListApi, getSUbScriptionWiseIncomeApi } from "../store/actions";
import loaderImg from '../../src/assets/gif/pattern.gif';
import flotingloaderFour from '../../src/assets/gif/floadtingfour.gif';
import loader from "../assets/gif/loaderIncome.gif"


const IncomeScreen = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('0')
  const isFirstRender = useRef(true);

  const [createdDateSortToggle, setCreatedDateSortToggle] = useState(false);

  const [selectedDurationTotalIncom, setSelectedDurationTotalIncom] = useState(
    "last_7_days"
  );

  const { loginDeatil } = useSelector((state) => ({
    loginDeatil: state.logingData,
  }));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const uniqueCode = userData?.username
  // const uniqueCode = loginDeatil?.logingData?.data ? loginDeatil?.logingData?.data[0]?.username : ""
  // const uniqueCode = "RE-09187"
  useEffect(() => {

    dispatch(
      getAllEarning({
        duration: duration,
        refcode: uniqueCode
      })
    );
    dispatch(getClientWiseIncomeListApi({
      duration: duration,
      refcode: uniqueCode
    }))
    dispatch(getSUbScriptionWiseIncomeApi({
      duration: duration,
      refcode: uniqueCode
    }))

  }, [])
  const { totalEarning } = useSelector((state) => ({
    totalEarning: state?.totalEarning?.totalEarning,
  }));
  const { SubScriptionWiseList } = useSelector((state) => ({
    SubScriptionWiseList: state?.SubScriptionWiseList,
  }));
  const { ClientWiseincomeList } = useSelector((state) => ({
    ClientWiseincomeList: state?.ClientWiseincomeList?.BrokrageList,
  }));
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const filteredResults = ClientWiseincomeList?.brokerage_client_arr?.filter((item) =>
    (item?.ClientId && item?.ClientId.toLowerCase().includes(searchValue.toLowerCase())) ||
    (item?.ClientName && item?.ClientName.toLowerCase().includes(searchValue.toLowerCase()))
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredResults?.slice(indexOfFirstItem, indexOfLastItem);
  const pageTotalManage = currentItems?.reduce((acc, item) => acc + parseFloat(item?.Earning.replace(',', '')), 0).toFixed(2);
  const totalPerPage = pageTotalManage
  let clientTotalPerPage;
  if (totalPerPage !== undefined && totalPerPage !== null && !isNaN(totalPerPage)) {
    const perPageTotalFloat = parseFloat(totalPerPage);
    clientTotalPerPage = perPageTotalFloat.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
  const totalPages = Math.ceil(filteredResults?.length / itemsPerPage);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setSearchValue(e.target.value);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //>>>>>>>>>>>>>>>>>>>>>>subscription wise dused>>>>>>>>>>>>>>>>>>>>>>>>>

  const [searchValueSub, setSearchValueSub] = useState('');
  const [currentPageSub, setCurrentPageSub] = useState(1);
  const filteredResultsSub = SubScriptionWiseList?.SubScriptionWiseList?.client_arr?.filter((item) =>
    (item?.clientCode && item?.clientCode.toLowerCase().includes(searchValueSub.toLowerCase())) ||
    (item?.clientName && item?.clientName.toLowerCase().includes(searchValueSub.toLowerCase()))
  );
  const indexOfLastItemSub = currentPageSub * itemsPerPage;
  const indexOfFirstItemSub = indexOfLastItemSub - itemsPerPage;
  const currentItemsSub = filteredResultsSub?.slice(indexOfFirstItemSub, indexOfLastItemSub);
  const pageTotalManageSub = currentItemsSub?.reduce((acc, item) => acc + parseFloat(item?.plan_amount.replace(',', '')), 0).toFixed(2);
  const SubtotalPerPage = pageTotalManageSub
  let SubTableTotal;
  if (SubtotalPerPage !== undefined && SubtotalPerPage !== null && !isNaN(SubtotalPerPage)) {
    const subPerPageFloat = parseFloat(SubtotalPerPage)
    const SubTableTotalInter = subPerPageFloat.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    SubTableTotal = SubTableTotalInter;

  }

  const totalPagesSub = Math.ceil(filteredResultsSub?.length / itemsPerPage);
  const pagesSub = Array.from({ length: totalPagesSub }, (_, i) => i + 1);

  const handleSearchChangeSub = (e) => {
    setCurrentPageSub(1);
    setSearchValueSub(e.target.value);
  };

  const handlePageChangeSub = (pageNumber) => {
    setCurrentPageSub(pageNumber);
  };

  const duration = localStorage.getItem("@month") == null ? "current_month" : localStorage.getItem("@month")

  const handleBrokSubsFilter = (e) => {
    const selectedValue = e.target.value;
    localStorage.setItem('@month', selectedValue)
    setSelectedDurationTotalIncom(selectedValue);
    dispatch(emptyTotalEarning());
    dispatch(getClientWiseIncomeListApi({
      duration: selectedValue,
      refcode: uniqueCode
    }))
    dispatch(getSUbScriptionWiseIncomeApi({
      duration: selectedValue,
      refcode: uniqueCode
    }))
    dispatch(
      getAllEarning({
        duration: selectedValue,
        refcode: uniqueCode
      })
    );
  };


  const [toggleValue, setToggleValue] = useState("income");
  const handleClientSort = () => {
    setCreatedDateSortToggle(!createdDateSortToggle);
  };
  const handleRecivedData = (receivedData) => {
    setToggleValue(receivedData);
  };

  //Total Income 
  const numberString = totalEarning?.earning_with_brok_subs
  const number = numberString?.replace(/,/g, '');
  let forNumTOtalEarning;
  if (number !== undefined && number !== null && !isNaN(number)) {
    forNumTOtalEarning = parseFloat(number)?.toLocaleString('en-IN', { maximumFractionDigits: 2, useGrouping: true });
  }
  //brokrage earning
  const brokrageValue = totalEarning?.brokerage_earning
  const brokerageEarning = brokrageValue?.replace(/,/g, '');
  let brokarageEarnValue;
  if (brokerageEarning !== undefined && brokerageEarning !== null && !isNaN(brokerageEarning)) {
    brokarageEarnValue = parseFloat(brokerageEarning)?.toLocaleString('en-IN', { maximumFractionDigits: 2, useGrouping: true });
  }
  const Loader = () => (
    <div className="loader-container">
      <img src={loader} alt="Loading..." className="loader-img" style={{ width: '50px', height: 'auto' }} />
    </div>
  );
  const LoaderOne = () => (
    <div className="loader-container">
      <img src={loader} alt="Loading..." className="loader-img" style={{ width: '50px', height: 'auto' }} />
    </div>
  );
  return (
    <>
      <div className="container main-dive m-px-30 income-screen-page">
        <div className="row income_cal_wr px-1">
          <div className="col-lg-6 col-md-6 mb-3 order-md-1">
            <div className="total_income_wr">
              <div className="ms-2">
                <div>
                  <div className="total_inc_blue"></div>
                  <span className="total_inc_text">Total Business Points :</span>

                  <span className="total_income_rupee" style={{ marginLeft: '10px' }}>
                    {totalEarning == "" ? <LoaderOne /> : forNumTOtalEarning}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 order-first order-md-2 col-md-6 col-12 mb-3 justify-content-end d-flex">
            <div className="lead_filter mxwidth-200">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleBrokSubsFilter}
                value={localStorage.getItem("@month")}
              >
                <option value="current_month">Current Month</option>
                <option value="last_month">Last Month</option>
                <option value="last_three_month">Last 3 Months</option>
                <option value="last_six_month">Last 6 Months</option>
                {/* <option value="one_year">Last 12 Months</option> */}
              </select>
            </div>
          </div>
          <div className="col-12 order-md-last">
            <div className="row">
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <div className="mb-3 justify-content-end d-flex w-100">
                  <ToggleButton onModalOpenedDataReceived={handleRecivedData} />
                </div>
              </div>
              <div className="col-12">
                <div className="row d-flex">
                  <div className="col-md-8">
                    <div className="income_clients_tiles">
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="card_wr">
                            <div className="total_ac_card">
                              <div className="total_ac_card_image trading_back_color">
                                <img
                                  src={ImagePath.totalAccountIcon}
                                  alt="user"
                                  className="total_ac_icon"
                                />
                              </div>
                              <div className="d-flex ms-3">
                                <span>Business Points</span>
                                <span className="clients_value">
                                  {ClientWiseincomeList?.client_count ? ClientWiseincomeList?.client_count : "0"} Clients
                                </span>
                                <span className="noOf_count">
                                  {totalEarning == "" ? <Loader /> : brokarageEarnValue}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6  mb-3">
                          <div className="card_wr">
                            <div className="total_ac_card">
                              <div className="total_ac_card_image total_trade_ac">
                                <img src={ImagePath.totalTradeIcon} alt="user" />
                              </div>
                              <div className="d-flex ms-3 ">
                                <span>Subscription Points</span>
                                <span className="clients_value">{SubScriptionWiseList?.SubScriptionWiseList?.total_count ? SubScriptionWiseList?.SubScriptionWiseList?.total_count : 0} Clients</span>
                                <span className="noOf_count">{SubScriptionWiseList?.SubScriptionWiseList?.subscribtion_earning ? SubScriptionWiseList?.SubScriptionWiseList?.subscribtion_earning : "0"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <IncomePieChart
                      toggleValue={toggleValue}
                      brokerageClients={ClientWiseincomeList?.client_count ? ClientWiseincomeList?.client_count : "0"}
                      brokerageAmount={totalEarning == undefined ? "0" : totalEarning?.brokerage_earning}
                      subscriptionClient={SubScriptionWiseList?.SubScriptionWiseList?.total_count ? SubScriptionWiseList?.SubScriptionWiseList?.total_count : 0}
                      subscriptionAmount={SubScriptionWiseList?.SubScriptionWiseList?.subscribtion_earning ? SubScriptionWiseList?.SubScriptionWiseList?.subscribtion_earning : "0"}
                      legendPositonH={"right"}
                      legendPositonV={"center"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="px-1">
            <div className="client_wise_income_table_wr ">

              <div className="clien_wise_text1 ps-1 text-center " style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                <h4>Client Wise Business Points</h4>
                <div
                  className="col-lg-3 mb-2"
                  style={{ alignItems: "center", display: "grid", }}
                >
                  <div className="search_bar search_name_no">
                    <input
                      className="me-5"
                      type="search"
                      placeholder="Search by Name, Client Id"
                      aria-label="Search"
                      onChange={handleSearchChange}
                    />
                    <img
                      className="img img-fluid"
                      src={ImagePath.search}
                      alt="search"
                    />
                  </div>

                </div>
              </div>




              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Client Name</th>
                      <th scope="col">Client ID</th>
                      <th scope="col">Business Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.ClientName ? item.ClientName : "NA"}</td>
                        <td>{item?.ClientId ? item.ClientId : "NA"}</td>
                        <td>{item?.Earning ? item.Earning : "NA"}</td>
                      </tr>
                    ))}
                  </tbody>
                  {filteredResults && filteredResults?.length > 0 ?
                    <tfoot>
                      <tr>
                        <td>Total:</td>
                        <td></td>
                        <td colSpan="2">
                          {/* <span>{clientTotalPerPage}</span> */}
                          <span>{totalEarning == "" ? "0" : brokarageEarnValue}</span>
                        </td>
                      </tr>
                    </tfoot> :
                    (filteredResults === undefined ? null :
                      <Loader />)
                  }
                </table>
              </div>
              {filteredResults && filteredResults.length > 0 ? null :
                (
                  <div className="text-center">
                    <span>No data found</span>
                  </div>
                )
              }
              {ClientWiseincomeList == undefined ? null
                :
                <Pagintion currentPage={currentPage} totalPages={totalPages} paginate={handlePageChange} listLength={ClientWiseincomeList?.brokerage_client_arr && ClientWiseincomeList?.brokerage_client_arr?.length} itemsPerPage={itemsPerPage} />
              }
            </div>
          </div>
        </div>

        <div className="row pt-4 mb-5">
          <div className="px-1">
            <div className="client_wise_income_table_wr ">

              <div className="clien_wise_text1 ps-1 text-center " style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                <h4>Subscription Wise Business Points</h4>
                <div
                  className="col-lg-3 mb-2"
                  style={{ alignItems: "center", display: "grid", }}
                >
                  <div className="search_bar search_name_no">
                    <input
                      className="me-1"
                      type="search"
                      placeholder="Search by Name, Client Id"
                      aria-label="Search"
                      onChange={handleSearchChangeSub}

                    />
                    <img
                      className="img img-fluid"
                      src={ImagePath.search}
                      alt="search"
                    />
                  </div>

                </div>
              </div>
              {/* {brokrageAllDeatils !== null ? ( */}
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th cope="col">Client Name</th>
                      <th scope="col">
                        <div onClick={() => handleClientSort()}>
                          Client ID
                        </div>
                      </th>
                      <th cope="col">Plan Name</th>
                      <th cope="col">Plan Value </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItemsSub && currentItemsSub?.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.clientName ? item?.clientName : "NA"}</td>
                        <td>{item?.clientCode}</td>
                        <td>{item?.plan_name}</td>
                        <td>{item?.plan_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                  {filteredResultsSub && filteredResultsSub?.length > 0 ?
                    <tfoot>
                      <tr>
                        <td>Total:</td>
                        <td></td>
                        <td></td>
                        {/* <td>{SubTableTotal}</td> */}
                        <td>{SubScriptionWiseList?.SubScriptionWiseList?.subscribtion_earning ? SubScriptionWiseList?.SubScriptionWiseList?.subscribtion_earning : "0"}</td>
                      </tr>
                    </tfoot>
                    :
                    null}

                </table>
              </div>
              {/* ) : ( */}
              {filteredResultsSub && filteredResultsSub?.length > 0 ?
                null :
                (<div className="text-center">
                  <span>No data not Found</span>
                </div>)
              }
              {filteredResultsSub && filteredResultsSub?.length > 0 ?
                <Pagintion currentPage={currentPageSub} totalPages={totalPagesSub} paginate={handlePageChangeSub} listLength={filteredResultsSub && filteredResultsSub?.length} itemsPerPage={itemsPerPage} />
                :
                null
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IncomeScreen;
