// import loginImage from "../assets/images/loginScreen/loginImage.png";
import loginImage from "../assets/images/loginScreen/login.svg";
import loginScreenLogo from "../assets/images/loginScreen/loginScreenLogo.svg";
import mobileLogo from "../assets/images/loginScreen/mobileIcon.png";
import emptyIndicator from "../assets/images/loginScreen/emptyIndicator.png";
import eyeOpen from "../assets/images/loginScreen/eyeOpen.png";
import blueIndicator from "../assets/images/loginScreen/blueIndicator.png";
import otpIcon from "../assets/images/loginScreen/otpIcone.png";
import fullBlueIndicator from "../assets/images/loginScreen/fullBlueIndicator.png";
import mail from "../assets/images/loginScreen/mail.svg";
import password from "../assets/images/loginScreen/password.svg";
import eyeClose from "../assets/images/loginScreen/eyeClose.svg";
import loginIcon from "../assets/images/loginScreen/loginIcon.svg";
import partnerCodeIcon from "../assets/images/loginScreen/partner-code-icon.svg";
import logo_patner  from "../assets/images/loginScreen/logo.svg";
import image from "../assets/images/loginScreen/image.png"

// Header screen
import bluePolygon from "../assets/images/header/bluePolygon.svg";
import leadsActiveIcon from "../assets/images/header/leadsActiveIcon.svg";
import msgTextIcon from "../assets/images/header/msgTextIcon.svg";
import clockBackward from "../assets/images/header/clockBackward.svg";
import reqPayoutIcon from "../assets/images/header/req_payout_icon.svg";
import logoutICon from "../assets/images/header/logout_icon.svg";

// Dashboard screen
import bell from "../assets/images/Dashboard/bell.png";
import closeIcon from "../assets/images/Dashboard/closeIcon.svg";
import shareEmail from "../assets/images/Dashboard/shareEmail.svg";
import shareTwitter from "../assets/images/Dashboard/shareTwitter.svg";
import search from "../assets/images/Dashboard/search.png";
import userProfile from "../assets/images/Dashboard/userProfile.png";
import notificationBell from "../assets/images/Dashboard/notification.svg";
import dashboardIcon from "../assets/images/Dashboard/dashboard-icon.png";
import incomeIcon from "../assets/images/Dashboard/incomeIcon.png";
import leadsIcon from "../assets/images/Dashboard/leadsIcon.png";
import phoneIcon from "../assets/images/Dashboard/phoneIcon.svg";
import totalAccountIcon from "../assets/images/Dashboard/total_account_icon.svg";
import totalTradeIcon from "../assets/images/Dashboard/total_trade_icon.svg";
import totalEarning from "../assets/images/Dashboard/total_earning_icon.svg";
import projectPayoutIcon from "../assets/images/Dashboard/project_payout.svg";
import unconvertedIcon from "../assets/images/Dashboard/unconverted_icon.svg";
import unconvertPercetErroIcon from "../assets/images/Dashboard/unconvrt_icon.svg";
import converted from "../assets/images/Dashboard/converted_icon.svg";
import convertedPercentErrow from "../assets/images/Dashboard/convert_percet_errow_icon.svg";
import referralBackground from "../assets/images/Dashboard/referal_background.svg";
import shareIcon from "../assets/images/Dashboard/shareIcon.svg";
import shareImage from "../assets/images/Dashboard/shareImage.svg";
import shareImageBlack from "../assets/images/Dashboard/share-black.svg";
import shareWtsapp from "../assets/images/Dashboard/whatsapp.svg";
import shareLinkd from "../assets/images/Dashboard/linkedin.svg";
import shareFb from "../assets/images/Dashboard/facebook.svg";
import opentrad_chart from "../assets/images/Dashboard/opentrad_chart.png";
import tradeUser from "../assets/images/Dashboard/tradeUser.svg";
import arrow from "../assets/images/Dashboard/arrow.svg";
import rupeeIcon from "../assets/images/Dashboard/rupeeIcon.svg";
import DateRangeCalendarIcon from "../assets/images/Dashboard/calendarIcon.svg";
import placeholder from "../assets/images/Dashboard/placeholder.png";
import call from "../assets/images/Dashboard/call.svg"

// Income Tab

import activeIncome from "../assets/images/Income/activeIncome.svg";
import deactiveDashboard from "../assets/images/Income/deactiveDashboard.svg";
import leftWhiteErrowDeactive from "../assets/images/Income/left_white_errow_deactive.svg";
import rightActiveErrow from "../assets/images/Income/rightActiveErrow.svg";
import portfolioIcon from "../assets/images/Income/portfolioIcon.svg";

// All Leads

import calendarIcon from "../assets/images/allLeads/calendar.svg";
import filterIcon from "../assets/images/allLeads/filter_icon.svg";
import polygonIcon from "../assets/images/allLeads/polygon.svg";
import sortDown from "../assets/images/allLeads/sortDown.svg";
import sortUp from "../assets/images/allLeads/sortUp.svg";
// Carousel
import sliderOne from "../assets/images/Carousel/acount-slide-1.png";
import sliderTwo from "../assets/images/Carousel/acount-slide-2.png";
import sliderThree from "../assets/images/Carousel/acount-slide-3.png";
import sliderBgOne from "../assets/images/Carousel/open-acount-bg-1.png";
import sliderBgTwo from "../assets/images/Carousel/open-acount-bg-2.png";
import CloseIcon from "../assets/images/Carousel/close-icon.png";

export const ImagePath = {
  image,
  logo_patner,
  partnerCodeIcon,
  call,
  sliderOne,
  sliderTwo,
  sliderThree,
  sliderBgOne,
  sliderBgTwo,
  CloseIcon,
  loginImage,
  loginScreenLogo,
  mobileLogo,
  emptyIndicator,
  blueIndicator,
  otpIcon,
  fullBlueIndicator,
  mail,
  password,
  loginIcon,
  eyeClose,
  bell,
  search,
  userProfile,
  notificationBell,
  dashboardIcon,
  incomeIcon,
  leadsIcon,
  phoneIcon,
  totalAccountIcon,
  totalTradeIcon,
  totalEarning,
  projectPayoutIcon,
  unconvertedIcon,
  unconvertPercetErroIcon,
  converted,
  convertedPercentErrow,
  referralBackground,
  shareIcon,
  shareImage,
  shareImageBlack,
  shareWtsapp,
  shareLinkd,
  shareFb,
  opentrad_chart,
  tradeUser,
  arrow,
  rupeeIcon,
  placeholder,
  activeIncome,
  deactiveDashboard,
  leftWhiteErrowDeactive,
  rightActiveErrow,
  calendarIcon,
  filterIcon,
  leadsActiveIcon,
  polygonIcon,
  bluePolygon,
  msgTextIcon,
  clockBackward,
  reqPayoutIcon,
  logoutICon,
  DateRangeCalendarIcon,
  sortDown,
  portfolioIcon,
  sortUp,
  closeIcon,
  shareEmail,
  shareTwitter,
  eyeOpen,
};
