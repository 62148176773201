import React, { useEffect, useMemo, useRef, useState } from "react";
import "./IncomeScreen.css";
import { ImagePath } from "../helper/ImagePath";
import { useDispatch, useSelector } from "react-redux";
import {
  emailWrapper,
  endDateSelectionFilter,
  getUserCode,
  mobileNumberWrapper,
} from "../helper/helpingMethods";
import dayjs from "dayjs";
import { isAfter, subMonths } from "date-fns";
import moment from "moment";
import Pagintion from "../common/Pagination";
import { getAllLeadsListApi, resetTradeIncome, resetTradeLeads } from "../store/allLeadsPage/getTotalAccountOpned/action";
import loaderImg from '../../src/assets/gif/pattern.gif';


const TotalTradedIncome = () => {
  const dispatch = useDispatch();
  // let uniqueCode = getUserCode();
  const [createdDateSortToggle, setCreatedDateSortToggle] = useState(false);
  const [toggleValue, setToggleValue] = useState("All");
  const [status, setStatus] = useState('0')
  const isFirstRender = useRef(true);

  const [searchV, setSearchValue] = useState("")

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { getTotalAccountOpned } = useSelector((state) => ({
    getTotalAccountOpned: state?.getTotalAccountOpned,
  }));
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const threeMonthsAgo = subMonths(new Date(), 3);
  const duration = localStorage.getItem("@month") == null ? "current_month" : localStorage.getItem("@month")
  const userData = JSON.parse(localStorage.getItem("userData"));
  const uniqueCode = userData?.username
  useEffect(() => {
    dispatch(
      getAllLeadsListApi({
        duration: duration,
        refcode: uniqueCode
      })
    );
  }, [])

  useEffect(() => {
    if (getTotalAccountOpned?.getTotalAccountOpned?.account_open_list !== undefined) {
      setOriginalData(getTotalAccountOpned.getTotalAccountOpned.account_open_list);
      setFilteredData(getTotalAccountOpned.getTotalAccountOpned.account_open_list);
    } else {
      setOriginalData([]);
      setFilteredData([]);
    }
  }, [getTotalAccountOpned]);


  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);

    let filteredResults;

    if (toggleValue === "All") {
      // Filter based on the search value only
      filteredResults = originalData?.filter(item =>
        (item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.mobile && item.mobile.toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.oowncode && item.oowncode.toLowerCase().includes(searchValue.toLowerCase()))
      );
    } else if (toggleValue === "Converted") {
      // Filter based on both search value and "Converted" status
      filteredResults = originalData.filter(item =>
        item.trade_status === "Traded" && isLastThreeMonths(item.last_Trade_Date) &&
        (
          (!searchValue || // Check if searchValue is empty or not provided
            (item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())) ||
            (item.mobile && item.mobile.toLowerCase().includes(searchValue.toLowerCase())) ||
            (item.oowncode && item.oowncode.toLowerCase().includes(searchValue.toLowerCase()))
          )
        )
      );
    } else if (toggleValue === "Unconverted") {
      // Filter based on both search value and "Unconverted" status
      filteredResults = originalData.filter(item =>
        (item.trade_status !== "Traded" || !isLastThreeMonths(item.last_Trade_Date)) &&
        (
          (!searchValue || // Check if searchValue is empty or not provided
            (item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())) ||
            (item.mobile && item.mobile.toLowerCase().includes(searchValue.toLowerCase())) ||
            (item.oowncode && item.oowncode.toLowerCase().includes(searchValue.toLowerCase()))
          )
        )
      );
    }
    setFilteredData(filteredResults);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const isLastThreeMonths = (date) => {
    const threeMonthsAgo = dayjs().subtract(3, 'months');
    return dayjs(date).isAfter(threeMonthsAgo);
  };

  const handleClientSort = () => {
    setCreatedDateSortToggle(!createdDateSortToggle);
  };

  const handleAccountTradeEarnFilter = (e) => {
    dispatch(resetTradeIncome());
    const selectedValue = e.target.value;
    localStorage.setItem('@month', selectedValue)
    const toDate = endDateSelectionFilter(selectedValue);
    const a = dayjs(toDate);
    const duration = selectedValue;
    const refcode = uniqueCode
    setToggleValue('All')
    dispatch(
      getAllLeadsListApi({
        duration: duration,
        refcode: uniqueCode
      })
    );
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  // const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPagesPL = Math.ceil(filteredData && filteredData?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleAllButtonClick = () => {
    setToggleValue("All");
    setFilteredData(originalData);
  };

  // Function for handling "Converted" button click
  const handleConvertedButtonClick = () => {
    setToggleValue("Converted");
    const filteredResults = originalData.filter(
      (item) => item.trade_status === "Traded" && isLastThreeMonths(item.last_Trade_Date)
    );
    setFilteredData(filteredResults);
  };

  // Function for handling "Unconverted" button click
  const handleUnconvertedButtonClick = () => {
    setToggleValue("Unconverted");
    const filteredResults = originalData?.filter(
      (item) =>
        (item.trade_status !== "Traded" || !isLastThreeMonths(item.last_Trade_Date))
    );
    setFilteredData(filteredResults);
  };

  const phoneEncripted = (phoneNumber) => {
    return phoneNumber.replace(/(\d{6})(\d{4})/, "******$2");
  };
  const emailEncrypted = (email) => {
    return email.replace(/(\w{6})(.*)@/, "******$2@");
  };

  const Loader = () => (
    <div className="loader-container">
      <img src={loaderImg} alt="Loading..." className="loader-img" style={{ width: '150px', height: 'auto' }} />
    </div>
  );
  return (
    <>
      <div className="container main-dive">
        <div className="uncoveredLead_filter d-flex justify-content-between mb-2">
          <div className="greetings">
          </div>
          <div className="">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => handleAccountTradeEarnFilter(e)}
              value={localStorage.getItem("@month")}
            >
              <option value="current_month" >Current Month</option>
              <option value="last_month">Last Month</option>
              <option value="last_three_month">Last 3 Months</option>
              <option value="last_six_month">Last 6 Months</option>
              {/* <option value="one_year">Last 12 Months</option> */}
            </select>
          </div>
        </div>
        <div className="uncoveredLead_filter d-flex justify-content-between">
          <div className="greetings">
            <p className="path_text">Dashboard > Total Accounts Opened</p>
          </div>
        </div>
        <div className="main_body_content">
          <div className="row">


            <div className="client_wise_income_table_wr">
              <div className="d-block d-md-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <div className="clien_wise_text1 ps-1 mb-2 ">
                  <h2>Total Accounts Opened</h2>
                </div>
                <div className="search_bar search_name_no">
                  <input
                    className="me-5"
                    type="search"
                    placeholder="Search by Name, Mobile No."
                    aria-label="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <img
                    className="img img-fluid"
                    src={ImagePath.search}
                    alt="search"
                  />
                </div>
                <div className="income_client">
                  <button
                    className={toggleValue === "All" ? "selected" : ""}
                    onClick={handleAllButtonClick}
                  >
                    All
                  </button>
                  <button
                    className={toggleValue === "Converted" ? "selected" : ""}
                    onClick={handleConvertedButtonClick}
                  >
                    Traded
                  </button>
                  <button
                    className={toggleValue === "Unconverted" ? "selected" : ""}
                    onClick={handleUnconvertedButtonClick}
                  >
                    Non-Traded
                  </button>
                </div>

              </div>
              {getTotalAccountOpned?.getTotalAccountOpned?.account_open_list !== undefined ?
                <>
                  {originalData && originalData?.length !== 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">
                              <div onClick={() => handleClientSort()}>
                                Account Opening Date
                              </div>
                            </th>
                            <th cope="col">Name</th>
                            <th cope="col">Client Id</th>

                            <th cope="col">Mobile No.</th>
                            <th cope="col">Email</th>
                            <th cope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>

                          {filteredData && filteredData?.slice(indexOfFirstItem, indexOfLastItem).map((item, i) => {
                            const isTraded = item?.trade_status === "Traded" && isAfter(new Date(item?.last_Trade_Date), threeMonthsAgo);
                            return (
                              <tr>
                                <td>{item?.my_etrades_opendate ? moment(item?.my_etrades_opendate).format("DD-MM-YYYY") : "NA"} </td>
                                <td>{item?.name == null ? "NA" : item?.name}</td>
                                <td>{item?.oowncode == null ? "NA" : item?.oowncode}</td>
                                <td>{mobileNumberWrapper(item?.mobile ? item?.mobile : "NA")}</td>
                                <td>{item?.email == null ? "NA" : emailWrapper(item?.email)}</td>
                                <td>{isTraded ? "Traded" : "Non-Traded"}</td>
                              </tr>
                            );
                          })}
                        </tbody>

                      </table>
                      <div>
                        <Pagintion currentPage={currentPage} totalPages={totalPagesPL} paginate={handlePageChange} listLength={filteredData && filteredData?.length} itemsPerPage={itemsPerPage} />
                      </div>
                    </div>
                  ) :
                    (<div className="text-center" style={{ marginTop: '20px' }}>
                      <span>No data not Found</span>
                    </div>)
                  }
                </>
                :
                <Loader />
              }
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default TotalTradedIncome;
