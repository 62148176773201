import React, { useState, useEffect } from "react";
import { ImagePath } from "../helper/ImagePath";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import "./Dashboard.css";
import CustomPopup from "../components/CustomPopup";
import { emptyTotalEarning, removeLoginData } from "../store/actions";
import { REMOVE_LOGIN_DATA } from "../store/LoginApi/actionType";
import { useDispatch, useSelector } from "react-redux";

function Header(Route) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIncomeTabAtive, setIsIncomeTabAtive] = useState(false);
  const [isDashboardAtive, setisDashboardAtive] = useState(false);
  const [isAllLeadsdAtive, setisAllLeadsdAtive] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const pathName1 = useLocation();
  const Navigate = useNavigate();
  const [isToggleOn, setIsToggleOn] = useState(false);

  const handleDivClick = () => {
    setIsToggleOn(!isToggleOn);
    document.body.classList.toggle('menu-open');
  };
  //user name here
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (isIncomeTabAtive) {
      setIsIncomeTabAtive(true);
      setisDashboardAtive(false);
    } else if (isDashboardAtive) {
      setisDashboardAtive(true);
      setIsIncomeTabAtive(false);
    }
  }, [isIncomeTabAtive, isDashboardAtive]);

  const { loginDeatil } = useSelector((state) => ({
    loginDeatil: state.logingData,
  }));

  const onNavigateReqPayout = () => {
    Navigate("/ReqPayout");
  };

  const logutApp = () => {
    let duration = "current_month"
    localStorage.setItem("userData", null)
    localStorage.setItem('@month', duration)
    localStorage.setItem('myValue', '0');
    dispatch(emptyTotalEarning());
    dispatch(removeLoginData())
    Navigate("/");
  }

  const handaleRouteDash = (e) => {
    e.preventDefault()
    Navigate("/console")
  }

  const handaleRouteIncome = (e) => {
    e.preventDefault()
    Navigate("/IncomeScreen")
  }

  const handaleRouteAllleads = (e) => {
    e.preventDefault()
    Navigate('/AllLeads', { state: "All" })
    // Navigate("/AllLeads")
  }


  const [isModalClose, setIsmodalClose] = useState(false);
  const handleRecivedData = (receivedData) => {
    setIsmodalClose(!receivedData);
  };

  const profileClickableView = () => {
    return (
      <div className="profile_wr" style={{ marginLeft: '10px', cursor: 'pointer' }}>
        <img className="img img-fluid" src={ImagePath.userProfile} alt="user" />
        <div className="d-flex ">
          <span>{userData ? userData?.fname : ""}</span>
          <span style={{ color: "gray" }}>{userData ? userData?.Designation : ""} - {userData ? userData?.username : ""}</span>
        </div>
      </div>
    );
  };
  const profileBodyView = () => {
    return (
      <div className="profile_popup">
        {/* <div className="profile_item_wr" onClick={() => onNavigateReqPayout()}>
          <img src={ImagePath.reqPayoutIcon} />
          <span>Request Payout</span>
        </div> */}
        <div className="profile_item_wr deactive_color" onClick={logutApp} >
          <img className="deactive_img" src={ImagePath.logoutICon} />
          <span className="logout_text">Logout</span>
        </div>

      </div>
    );
  };


  /// contact popup sport 
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const myValue = localStorage.getItem('myValue');
  return (

    <div className="top_bar">
      <div className="top_sub_menu_wr">
        <div className="container">
          <div className="top_sub_menu pb-0">
            <div className="left-side d-flex">
              <div className="logo">
                <Link to="/console">
                  <img
                    className="img img-fluid"
                    src={ImagePath.loginScreenLogo}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className="right-side d-flex">
              <div onClick={openModal} style={{ cursor: 'pointer' }}>
                <samp>Help & Support</samp>
              </div>
              <CustomPopup
                onModalOpenedDataReceived={handleRecivedData}
                content={profileBodyView()}
                onClickView={profileClickableView()}
                polyColor="white"
              />
              <div onClick={handleDivClick} className="mobile-toggle-icons" >
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="main_nav_wr">
          <span className="sidebar-close" onClick={handleDivClick}>&nbsp;</span>
          <nav className="main_tabs d-flex justify-content-between">
            <div className="d-flex">
              <a href="/console" className="ps-0 mb-0 d-flex align-items-center me-md-4" style={{ textDecoration: 'none' }}>
                <ul
                  className="ps-0 mb-0 d-flex align-items-center me-4 "
                // onClick={handleDashboardTab}
                >
                  <img
                    src={
                      pathName1.pathname === "/console"
                        ? ImagePath.dashboardIcon
                        : ImagePath.deactiveDashboard
                    }
                    alt="dashboard"
                  />
                  <a
                    href="/console"
                    className="ms-2 menu_ancher"
                    style={{
                      color:
                        pathName1.pathname === "/console" ? "#5367FC" : "#545A6D",
                      borderBottom: pathName1.pathname === "/console" ? " 2px solid #5367FC" : "2px solid #fff",

                    }}
                  >
                    Console
                  </a>
                </ul>
              </a>

              <a href="/IncomeScreen" className="ps-0 mb-0 d-flex align-items-center me-md-4" style={{ textDecoration: 'none' }}>
                <ul
                  className="ps-0 mb-0 d-flex align-items-center me-4"
                >
                  <img
                    src={
                      pathName1.pathname === "/IncomeScreen"
                        ? ImagePath.activeIncome
                        : ImagePath.incomeIcon
                    }
                    alt="income"
                  />
                  <a
                    href="/IncomeScreen"
                    className="ms-2 menu_ancher"
                    style={{
                      color:
                        pathName1.pathname === "/IncomeScreen"
                          ? "#5367FC"
                          : "#545A6D",
                      borderBottom: pathName1.pathname === "/IncomeScreen" ? " 2px solid #5367FC" : "2px solid #fff",

                    }}
                  >
                    Business Points
                  </a>
                </ul>
              </a>

              <a href="/AllLeads" className="ps-0 mb-0 d-flex align-items-center me-md-4" style={{ textDecoration: 'none' }}>

                <ul
                  className="ps-0 mb-0 d-flex align-items-center me-4"
                  onClick={handaleRouteAllleads}
                >
                  <img
                    src={
                      pathName1.pathname === "/AllLeads"
                        ? ImagePath.leadsActiveIcon
                        : ImagePath.leadsIcon
                    }
                    alt="dashboard"
                  />
                  <a
                    href="/AllLeads"
                    className="ms-2 menu_ancher"
                    style={{
                      color:
                        pathName1.pathname === "/AllLeads" ? "#5367FC" : "#545A6D",
                      borderBottom: pathName1.pathname === "/AllLeads" ? " 2px solid #5367FC" : "2px solid #fff",
                      // border-bottom: 2px solid rgb(83, 103, 252);
                    }}
                  >
                    All Leads
                  </a>
                </ul>
              </a>
            </div>
            <div class="profile-drop">
              <div class="profile-icon" onClick={() => { setIsModalOpen(!isModalOpen) }}>
                <img className="img img-fluid" src={ImagePath.userProfile} alt="user" />
              </div>
              {isModalOpen == false ? null :
                <div class="dropdown-item dropdown-menu">
                  <ul>
                    <li className="name">{userData ? userData?.fname : ""}</li>
                    <li className="des">{userData ? userData?.username : ""}</li>
                    {myValue === 1 ?
                      <li onClick={() => { logutApp() }}>Logout</li> : ""
                    }
                  </ul>
                </div>
              }
            </div>
          </nav>
        </div>
      </div>

      {showModal && (
        <div className="contact-modal-wraper">
          <div className="contact-modal">
            <div className="contact-header">
              <h4>Help & Support</h4>
              <span onClick={closeModal}>&#x2715;</span>
            </div>
            <div className="text-body">
              <div className="contact-list">
                <p>Algo Trading Support</p>
                <h6>09090491913</h6>
              </div>
              <div className="contact-list">
                <p>Call & Trade</p>
                <h6>09090491913</h6>
              </div>
              <div className="contact-list">
                <p>Customer Support</p>
                <h6>09090491913</h6>
              </div>
              <div className="contact-list">
                <p>Customer Support</p>
                <h6>support@bigul.co</h6>
              </div>
            </div>
            <button className="modal-close-btn">Close</button>
          </div>
        </div>

      )}
    </div>
  );
}

export default Header;
